








import Vue from "vue";

import {
  distinct,
  distinctByProp,
  groupBy,
  groupByF,
  paginate,
  sleep,
} from "@/utils/utils";
import MemberProfile from "@/components/MemberProfile.vue";
import { UserService } from "@/services/UserService";
import { MessagesService } from "@/services/MessagesService";

const messagesService = new MessagesService();
const userService = new UserService();

export default Vue.extend({
  // components: { MemberProfile },
  // data(): any {
  //   return {
  //     member: null as any,
  //   };
  // },
  // methods: {
  //   async load() {
  //     console.log(this.$router.currentRoute.params);
  //     const userId = this.$router.currentRoute.params.id as number;
  //     const user = userService.loadUsers([userId], []);

  //     //await this.loadMessages();
  //   },
  //   async loadMessages() {
  //     this.messages.splice(0);
  //     this.users.splice(0);

  //     let users = await userService.getFavourite();

  //     const myMessages = await messagesService.loadMyMessages();

  //     const usersFromMyMessages = await userService.loadUsersFromForwardedMessages(
  //       myMessages
  //     );

  //     users.splice(users.length, 0, ...usersFromMyMessages);

  //     const usersIds = users.map((u) => u.id);
  //     const messages = await messagesService.loadFromCache(usersIds, []);

  //     messages.push(...myMessages);

  //     this.messages.splice(0, 0, ...messages);

  //     const group = groupByF(this.messages, (m: any) => m.from_id.user_id);

  //     for (let user of users) {
  //       user.messages = group[user.id] || [];
  //       user.photos = await userService.getUserPhotos(user);
  //     }
  //     users.sort(
  //       (a, b) =>
  //         b.messages &&
  //         b.messages[0] &&
  //         b.messages[0].date - a.messages &&
  //         a.messages[0] &&
  //         a.messages[0].date
  //     );
  //     users = distinctByProp(users, "id");
  //     this.users.splice(this.users.length, 0, ...users);
  //     //this.users.splice(this.users.length, 0, users[1]);

  //     console.log(this.users);
  //   },
  // },
  // computed: {},
  // created() {
  //   this.load();
  // },
  // watch: {
  //   $route(to, from) {
  //     console.log(to);
  //   },
  // },
});
