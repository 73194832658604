






















































import Vue from "vue";
import Component from "vue-class-component";

import { distinct, groupBy, groupByF, paginate, sleep } from "@/utils/utils";
import MemberProfile from "@/components/MemberProfile.vue";
import { UserService } from "@/services/UserService";
import { MessagesService } from "@/services/MessagesService";

@Component({
  components: {
    MemberProfile,
  },
})
export default class extends Vue {
  private messagesService = new MessagesService();
  private userService = new UserService();

  chats: any[] = [];
  selected: number[] = [];
  messages: any[] = [];
  users: any[] = [];
  searchText = "";
  countToLoad = 50;
  loadCachedMessages = false;

  async created() {
    await this.load();
  }

  load() {
    this.$mtproto
      .call("messages.getDialogs", {
        offset_peer: { _: "inputPeerEmpty" },
        limit: 50,
      })
      .then((result: any) => {
        const chats = result.chats.filter(
          (c: any) => c._ == "channel" && !c.left && !c.creator
        );
        chats.map((chat: any) => {
          const dialog = result.dialogs.filter((d: any) => {
            return (
              d.peer &&
              d.peer._ == "peerChannel" &&
              d.peer.channel_id == chat.id
            );
          })[0];

          chat.dialog = dialog;
        });
        this.chats.splice(0, 0, ...chats);
        //console.log(`messages.getAllChats:`, chats);
      });
  }

  async loadMessages() {
    this.messages.splice(0);
    this.users.splice(0);

    const chats = this.selected.map((i: number) => this.chats[i]);
    const messages = await this.messagesService.searchByText(
      chats,
      this.searchText,
      this.countToLoad,
      (s, total, done) => {
        this.$store.commit("setMessage", s);
        this.$store.commit("setProgress", (done / total) * 100);
      }
    );
    this.messages.splice(0, 0, ...messages);

    const group = groupByF(this.messages, (m: any) => m.from_id.user_id);

    const users = await this.userService.loadUsers(
      Object.keys(group)
        .map((i) => parseInt(i))
        .filter((i) => group[i]),
      group
    );
    for (let user of users) {
      user.messages = group[user.id];
    }
    users.sort((a, b) => b.messages[0].date - a.messages[0].date);
    this.users.splice(this.users.length, 0, ...users);

    this.$store.commit("setMessage", "");
    this.$store.commit("setProgress", 0);

    //console.log(this.users);
  }

  async blockUser(user: any) {
    this.users.splice(this.users.indexOf(user), 1);
  }
}
