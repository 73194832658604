import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    message: '',
    progress: 0
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setMessage(state, payload){
      state.message = payload;
    },
    setProgress(state, payload){
      state.progress = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})