
























































































import Vue from "vue";
import { distinctByF, min, max, distinctByProp, distinctByStringProp } from "@/utils/utils";
import MemberPhoto from "@/components/MemberPhoto.vue";
import UserPhoto from "@/components/UserPhoto.vue";
import { UserService } from "@/services/UserService";
import { MessagesService } from "@/services/MessagesService";
import PhotoService, { Photo } from "@/services/PhotoService";
import Component from "vue-class-component";

// Define the props by using Vue's canonical way.
const MemberProps = Vue.extend({
  props: {
    member: Object,
    indexText: String,
  },
});

@Component<MemberProfile>({
  components: {
    UserPhoto,
    MemberPhoto,
  },
  watch: {
    isOpenPhotosDialog(value: boolean) {
      if (!value) {
        this.scrollToTop();
      }
    },
  },
})
export default class MemberProfile extends MemberProps {
  private messagesService = new MessagesService();
  private userService = new UserService();

  isOpenPhotosDialog = false;
  distinctPhotos: any[] = [];
  message = "";

  async saveMessage(message: any) {
    this.$set(message, "favourite", true);
    this.userService.favouriteUser(this.member);

    var param = {
      id: [message.id],
      random_id: [message.id],
      from_peer: {
        _: "inputPeerChannel",
        channel_id: message.chat.id,
        access_hash: message.chat.access_hash,
      },
      to_peer: { _: "inputPeerSelf" },
    };
    await this.$mtproto.call("messages.forwardMessages", param);
  }

  async searchMessages() {
    const messages = await this.messagesService.searchByUser(
      this.member,
      (s, total, done) => {
        this.message = s;
        this.$store.commit("setMessage", s);
        this.$store.commit("setProgress", (done / total) * 100);
      }
    );
    this.member.messages.splice(0, 0, ...messages);
    this.message = `Найдено ${messages.length} сообщений`;
    this.$store.commit("setProgress", 0);

    this.$set(
      this.member,
      "photos",
      await this.userService.getUserPhotos(this.member)
    );
    const updateUser = this.userService.updateUser(this.member) as any;

    if (updateUser.username)
      this.$set(this.member, "username", updateUser.username);
    if (updateUser.first_name)
      this.$set(this.member, "first_name", updateUser.first_name);
    if (updateUser.last_name)
      this.$set(this.member, "last_name", updateUser.last_name);
  }

  async updateMessages() {
    const messages = await this.messagesService.updateMessages(
      this.member.messages
    );
    this.member.messages.splice(0, this.member.messages.length, ...messages);
  }

  async loadFromCache() {
    const messages = await this.messagesService.loadFromCache([this.member.id]);
    this.member.messages.splice(0, 0, ...messages);
    this.message = `Добавлено ${messages.length} сообщений`;
  }

  async loadProfilePhotos() {
    this.$set(
      this.member,
      "photos",
      await this.userService.getUserPhotos(this.member)
    );
  }

  async unfovoutiteUser() {
    await this.userService.unfavouriteUser(this.member);
    this.$emit("block-user", this.member);
  }

  async block() {
    this.scrollToTop();
    await this.userService.blockUser(this.member);
    this.$emit("block-user", this.member);
  }

  scrollToTop() {
    (this.$refs.header as any).scrollIntoView();
  }

  get messages() {
    //return distinctByProp(this.member.messages, "message");
    // for (let message of this.member.messages) {
    //   if (!message.message2 && message.message) {
    //     message.message2 = message.message
    //       .replaceAll(/[^A-Za-z0-9_А-Яа-я]/g, "")
    //       .toUpperCase();
    //   }
    // }
    return distinctByStringProp(this.member.messages, "message");
  }

  get photos() {
    let items = this.member.messages
      .filter((m: any) => {
        return m.media && m.media.photo;
      })
      .map((m: any) => {
        //m.media.photo.message = m;
        return m.media.photo;
      });
    items.sort((a: Photo, b: Photo) => a.id.localeCompare(b.id));
    items = distinctByF(items, function (p: any) {
      return PhotoService.calcHash(p);
    }).concat(this.member.photos || []);

    return items;
  }

  get photoSize() {
    return this.isOpenPhotosDialog ? null : "m";
  }
  get firstMessageDate() {
    return min(this.member.messages, (m: any) => m.date);
  }
  get lastMessageDate() {
    return max(this.member.messages, (m: any) => m.date);
  }
}
