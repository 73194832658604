export function groupBy(xs: any[], key: any) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function groupByF(xs: any[], keyF: any) {
  return xs.reduce(function (rv, x) {
    let key = keyF(x);
    (rv[key] = rv[key] || []).push(x);
    return rv;
  }, {});
};

export function paginate(arr: any[], size: number): any[][] {
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size)
    let page = acc[idx] || (acc[idx] = [])
    page.push(val)

    return acc
  }, [])
}

export function min(xs: any[], propF: any) {
  return xs.reduce(function (rv, x) {
    let value = propF(x);
    return Math.min(rv, value);
  }, Number.MAX_VALUE);
}

export function max(xs: any[], propF: any) {
  return xs.reduce(function (rv, x) {
    let value = propF(x);
    return Math.max(rv, value);
  }, Number.MIN_VALUE);
}

function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}
export function distinct(arr: any[]) {
  return arr.filter(onlyUnique);
}

export function distinctByProp(arr: any[], prop: string) {
  const map = arr.map((item: any) => item[prop]);
  return arr.filter(function onlyUnique(value: any, index: number, self: any[]) {
    return map.indexOf(value[prop]) === index;
  });
}

export function distinctByStringProp(arr: any[], prop: string) {

  const collator = new Intl.Collator('ru-RU', { sensitivity: 'base', ignorePunctuation: true });

  const map = arr.slice();

  map.sort((a, b) => {
    return collator.compare(a[prop], b[prop]);
  });

  return map.filter((item, index, self) => {
    return index == 0 || collator.compare(item[prop], self[index - 1][prop]) != 0;
  });
}

export function distinctByF(arr: any[], propF: any) {
  const map = arr.map((item: any) => propF(item));
  return arr.filter(function onlyUnique(value: any, index: number, self: any[]) {
    return map.indexOf(propF(value)) === index;
  });
}

export function sleep(ms: number = 1000) {
  return new Promise(function (r) {
    setTimeout(r, ms);
  });
}

export default function throttle(callback: any, limit: number) {
  var wait = false;
  return function () {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
    }
  }
}