import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import mtproto from './plugins/mtproto';
import { MessagesService } from './services/MessagesService';

Vue.config.productionTip = false

const dateOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit'
};

Vue.filter('date', function (value: number) {
  return new Date(value * 1000).toLocaleDateString('ru', dateOptions);
});

const datetimeOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: "2-digit",
  minute: "2-digit"
};

Vue.filter('datetime', function (value: number) {
  return new Date(value * 1000).toLocaleString('ru', datetimeOptions);
});

new Vue({
  router,
  store,
  vuetify,
  mtproto,
  render: h => h(App)
}).$mount('#app')

const messagesService = new MessagesService();
//mtproto.updates.on('updates', messagesService.onMTProtoMessage);