

















































import Vue from "vue";

import { groupByF } from "@/utils/utils";
import MemberProfile from "@/components/MemberProfile.vue";
import { UserService } from "@/services/UserService";
import { MessagesService } from "@/services/MessagesService";

const messagesService = new MessagesService();
const userService = new UserService();

export default Vue.extend({
  components: { MemberProfile },
  data(): any {
    return {
      chats: [] as any[],
      selected: [] as number[],
      messages: [] as any[],
      users: [] as any[],
      searchText: "",
      countToLoad: 100 as number,
      onlyNew: false,
      loadCachedMessages: false,
    };
  },
  methods: {
    load() {
      this.chats.splice(0);
      this.messages.splice(0);
      this.users.splice(0);

      this.$mtproto
        .call("messages.getDialogs", {
          offset_peer: { _: "inputPeerEmpty" },
          limit: 50,
        })
        .then((result: any) => {
          const chats = result.chats.filter(
            (c: any) => c._ == "channel" && !c.left && !c.creator
          );
          chats.map((chat: any) => {
            const dialog = result.dialogs.filter((d: any) => {
              return (
                d.peer &&
                d.peer._ == "peerChannel" &&
                d.peer.channel_id == chat.id
              );
            })[0];

            chat.dialog = dialog;
          });
          this.chats.splice(0, 0, ...chats);
          //console.log(`messages.getAllChats:`, chats);
        });
    },
    reload() {
      this.chats.splice(0);
      this.messages.splice(0);
      this.users.splice(0);
      this.load();
    },
    async loadMessages() {
      this.messages.splice(0);
      this.users.splice(0);

      try {
        const chats = this.selected.map((i: number) => this.chats[i]);
        const messages = await messagesService.loadMessages(
          chats,
          { countToLoad: this.countToLoad, onlyNew: this.onlyNew },
          (s, total, done) => {
            this.message = s;
            this.$store.commit("setMessage", s);
            this.$store.commit("setProgress", (done / total) * 100);
          }
        );

        this.$store.commit(
          "setMessage",
          "Загружено сообщений: " + messages.length
        );

        this.messages.splice(0, 0, ...messages);

        const group = groupByF(this.messages, (m: any) => m.from_id.user_id);

        const users = await userService.loadUsers(
          Object.keys(group)
            .map((i) => parseInt(i))
            .filter((i) => group[i]),
          group
        );
        for (let user of users) {
          user.messages = group[user.id];
        }
        users.sort((a, b) => b.messages[0].date - a.messages[0].date);
        this.users.splice(this.users.length, 0, ...users);
      } catch (e) {
        this.$store.commit("setMessage", (e as any).toString());
      }
      //console.log(this.users);
    },
    async blockUser(user: any) {
      this.users.splice(this.users.indexOf(user), 1);
    },
  },
  computed: {
    filteredUsers: function (): any[] {
      if (!this.searchText) return this.users;

      var st = this.searchText.toLocaleUpperCase();
      return this.users.filter((user: any) => {
        return (
          (user.first_name &&
            user.first_name.toLocaleUpperCase().includes(st)) ||
          (user.last_name && user.last_name.toLocaleUpperCase().includes(st)) ||
          (user.username && user.username.toLocaleUpperCase().includes(st)) ||
          (user.messages &&
            user.messages.some((m: any) => {
              return (
                m.message &&
                (m.message.toLocaleUpperCase().includes(st) ||
                  (m.chat &&
                    m.chat.title &&
                    m.chat.title.toLocaleUpperCase().includes(st)))
              );
            }))
        );
      });
    },
  },
  created() {
    this.load();
  },
});
