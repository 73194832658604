




import Vue from "vue";
import PhotoService, {
  Photo,
  PhotoSize,
} from "@/services/PhotoService";

export default Vue.extend({
  props: {
    photo: Photo,
    size: String,
  },
  data: () => ({
    dataUrl: "",
    photoSize: { w: 200, h: 300 } as PhotoSize,
  }),
  async created() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.dataUrl) {
        this.dataUrl = PhotoService.getThumb(this.photo);
      }
      if (this.size != "i") {
        const photoFile = await PhotoService.getPhoto(this.photo, this.size);
        if (photoFile) {
          this.dataUrl = photoFile.url;
          this.photoSize = photoFile.size;
        }
      }
    },
  },
  watch: {
    size: function (val, oldVal) {
      this.load();
    },
  },
});
