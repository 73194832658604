import { distinct, paginate, sleep } from '@/utils/utils';
import mtproto from '@/plugins/mtproto';
import { UsersStore, UsersBlockedStore, UsersFavouriteStore, getDocs, saveBatch } from './StoreService';
const _blocked: string[] = [];
const _cached: any[] = [];

export class UserService {




    private store = UsersStore;
    protected blocked = UsersBlockedStore;

    async getCachedUsers(ids: Number[], includeBloked: boolean = false) {
        //console.log('_cached', _cached.length, 'total to load', ids.length);

        if (!includeBloked) {
            if (!_blocked.length) {
                const lastYear = new Date();
                lastYear.setMonth(lastYear.getMonth() - 6);
                const data = await this.blocked.where("date", ">=", lastYear).get();
                const bloked = data.docs.map(u => u.id);
                _blocked.push(...bloked);
            }
            ids = ids.filter(x => !_blocked.includes(x.toString()));
        }

        const result = _cached.filter(x => ids.includes(x.id));

        ids = ids.filter(x => !_cached.some(y => y.id == x));

        //console.log('result', result.length, 'not in cache', ids.length);

        const pages = paginate(ids, 10);
        for (let id of pages) {
            const users = await getDocs(this.store.where("id", "in", id));
            result.push(...users);
            _cached.push(...users);
            //console.log(`added to cache ${users.length}`);
        }
        const foundIds = result.map(u => u.id);
        const toFindIds = ids.filter(i => !foundIds.includes(i));
        return { result: result as any[], toLoad: toFindIds as number[] };
    }

    public async loadUsers(ids: number[], messages: any[]): Promise<any[]> {

        const { result, toLoad } = await this.getCachedUsers(ids);
        const userPages = paginate(toLoad, 50);
        for (let page of userPages) {
            const param = {
                id: page.map((userId) => {
                    return {
                        _: "inputPeerUserFromMessage",
                        user_id: userId,
                        msg_id: messages[userId][0].id,
                        peer: {
                            _: "inputPeerChannel",
                            channel_id: messages[userId][0].chat.id,
                            access_hash: messages[userId][0].chat.access_hash,
                        },
                    };
                }),
            };
            try {
                const users = (await mtproto.call("users.getUsers", param) as any[]).map((u: any) => {
                    return {
                        id: u.id,
                        access_hash: u.access_hash,
                        first_name: u.first_name || "",
                        last_name: u.last_name || "",
                        username: u.username || ""

                    }
                });
                result.push(...users);
                _cached.push(...users);
                await saveBatch(this.store, users, (u: any) => u.id);
                console.log(`total users ${result.length}, new ${toLoad.length} users, in cache ${_cached.length}`);
            } catch (e) {
                console.log(e);
            }


            await sleep();
        }
        return result;
    }

    public async updateUser(user: any): Promise<any> {
        const users = (await mtproto.call("users.getUsers", {
            id: [{
                '_': 'inputUser',
                user_id: user.id,
                access_hash: user.access_hash
            }]
        }) as any[]).map((u: any) => {
            return {
                id: u.id,
                access_hash: u.access_hash,
                first_name: u.first_name || "",
                last_name: u.last_name || "",
                username: u.username || ""

            }
        });

        await saveBatch(this.store, users, (u: any) => u.id);


        for (let index = 0; index < _cached.length; index++) {
            if (_cached[index].id == user.id) {
                _cached.splice(index, 1, users[0])
                break;
            }
        }

        return users[0];
    }

    public async loadUsersFromForwardedMessages(messages: any[]): Promise<any[]> {
        const ids = distinct(messages.map((m: any) => m.fwd_from.from_id.user_id));
        const { result, toLoad } = await this.getCachedUsers(ids, true);
        const messagesToLoad = messages.filter(m => toLoad.includes(m.fwd_from.from_id.user_id));

        const userPages = paginate(messagesToLoad, 100);
        for (let page of userPages) {
            const param = {
                id: page.map((m) => {
                    return {
                        _: "inputPeerUserFromMessage",
                        user_id: m.fwd_from.from_id.user_id,
                        msg_id: m.id,
                        peer: {
                            _: "inputPeerSelf",
                        },
                    };
                }),
            };

            const users = (await mtproto.call("users.getUsers", param) as any[]).map((u: any) => {
                return {
                    id: u.id,
                    access_hash: u.access_hash,
                    first_name: u.first_name || "",
                    last_name: u.last_name || "",
                    username: u.username || ""
                }
            });
            result.push(...users);
            await saveBatch(this.store, users, (u: any) => u.id);
            console.log(`loaded ${result.length} of ${toLoad.length} users`);
            await sleep();
        }
        return result;
    }

    public async getUserPhotos(user: any): Promise<any[]> {
        const { photos } = await mtproto.call("photos.getUserPhotos", {
            user_id: {
                '_': 'inputUser',
                user_id: user.id,
                access_hash: user.access_hash
            }
        });
        return photos;
    }

    public async getFavourite(): Promise<any[]> {
        return (await UsersFavouriteStore.get()).docs.map(m => m.data());
    }

    public async blockUser(user: any) {
        const clone = {
            id: user.id,
            access_hash: user.access_hash,
            first_name: user.first_name || "",
            last_name: user.last_name || "",
            username: user.username || "",
            date: new Date()
        };
        UsersBlockedStore.doc(user.id.toString()).set(clone);
        _blocked.push(user.id.toString());
        await this.unfavouriteUser(user);

    }
    public async favouriteUser(user: any) {
        const clone = {
            id: user.id,
            access_hash: user.access_hash,
            first_name: user.first_name || "",
            last_name: user.last_name || "",
            username: user.username || ""
        };
        UsersFavouriteStore.doc(user.id.toString()).set(clone);
        await mtproto.call("contacts.addContact", {
            add_phone_privacy_exception: false,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            id: {
                '_': 'inputUser',
                user_id: user.id,
                access_hash: user.access_hash
            }
        });
    }
    public async unfavouriteUser(user: any) {
        UsersFavouriteStore.doc(user.id.toString()).delete();
        const { messages } = await mtproto.call("messages.getHistory", {
            peer: {
                _: "inputPeerSelf"
            },
            offset_id: 0,
            offset: 0,
            limit: 99,
        });

        const messagesToDeleteFromMy = messages.filter((m: any) => m.fwd_from?.from_id?.user_id == user.id).map((m: any) => m.id);
        if (messagesToDeleteFromMy.length) {
            await mtproto.call("messages.deleteMessages", {
                id: messagesToDeleteFromMy
            });
        }

        await mtproto.call("contacts.deleteContacts", {
            id: [{
                '_': 'inputUser',
                user_id: user.id,
                access_hash: user.access_hash
            }]
        });
    }

}