import { distinct, groupByF, paginate, sleep } from "@/utils/utils";
import mtproto from '@/plugins/mtproto';
import { getDocs, MessagesStore, PrivateMessagesStore, saveBatch } from "./StoreService";

export class MessagesService {
    public async loadMessages(chats: any[], loadOptions: { countToLoad: number, onlyNew?: boolean },
        progress?: (message: string, total: number, done: number) => void): Promise<any[]> {
        const result = [] as any[];
        for (let chat of chats) {
            let from_message_id = 0;
            let left = loadOptions.onlyNew ? Math.min(chat.dialog.unread_count, loadOptions.countToLoad) : loadOptions.countToLoad;
            while (left > 0) {
                const { messages } = await mtproto.call("messages.getHistory", {
                    peer: {
                        _: "inputPeerChannel",
                        channel_id: chat.id,
                        access_hash: chat.access_hash,
                    },
                    offset_id: from_message_id,
                    offset: 0,
                    limit: Math.min(100, left),
                });
                if (!messages || !messages.length) {
                    break;
                }
                from_message_id = messages[messages.length - 1].id;
                left -= messages.length;
                console.log(
                    "%s-%s: %s <> %s, %s",
                    chat.id,
                    messages[messages.length - 1].id,
                    messages.length,
                    left,
                    new Date(messages[messages.length - 1].date * 1000)
                );

                messages.forEach((message: any) => {
                    message.chat = { id: chat.id, access_hash: chat.access_hash, title: chat.title };
                    message._id = message.id + '_' + message.chat.id;
                });
                //console.log(messages)
                result.splice(
                    result.length,
                    0,
                    ...messages.filter((m: any) => m.from_id && m.from_id.user_id && m.peer_id.channel_id)
                );

                if (typeof progress === 'function') {
                    progress(`${chat.title} (${result.length}/${loadOptions.countToLoad})`, loadOptions.countToLoad, result.length);
                }

                await sleep();
            }

            const da = await mtproto.call("channels.readHistory", {
                channel: {
                    _: "inputChannel",
                    channel_id: chat.id,
                    access_hash: chat.access_hash,
                },
                max_id: 0
            });
            if (da && chat.dialog) {
                chat.dialog.unread_count = 0;
            }

        }

        await this.save(result);

        result.sort((a, b) => b.date - a.date);
        return result;



    };

    public async updateMessages(toUpdate: any[]): Promise<any[]> {
        const result = [] as any[];
        const chats = groupByF(toUpdate.filter(m => m.chat), (m: any) => m.chat.id);

        for (let chat in chats) {
            const { messages } = await mtproto.call("channels.getMessages", {
                channel: {
                    _: "inputChannel",
                    channel_id: chats[chat][0].chat.id,
                    access_hash: chats[chat][0].chat.access_hash
                },
                id: chats[chat].map((m: any) => {
                    return {
                        _: "inputMessageID",
                        id: m.id
                    }
                })
            });
            messages.forEach((message: any) => {
                message.chat = chats[chat][0].chat;

            });
            result.push(...messages);
        }
        await this.save(result);

        const { messages } = await mtproto.call("messages.getMessages", {
            id: toUpdate.filter(m => !m.chat).map((m: any) => {
                return {
                    _: "inputMessageID",
                    id: m.id
                }
            })
        });
        result.push(...messages);
        return result;
    }

    public async loadMyMessages(): Promise<any[]> {
        const result = await mtproto.call("messages.getHistory", {
            peer: {
                _: "inputPeerSelf"
            },
            offset_id: 0,
            offset: 0,
            limit: 99,
        });
        const messages = result.messages.filter((m: any) => m.fwd_from && m.fwd_from.from_id);
        messages.forEach((message: any) => {
            message.from_id = message.fwd_from.from_id;
            message.date = message.fwd_from.date;
        });
        return messages;
    }

    public async loadFromCache(userIds: number[]): Promise<any[]> {
        const result = [] as any[];
        const userPages = paginate(userIds, 10);
        for (let page of userPages) {
            const messages = await getDocs(MessagesStore.where("from_id.user_id", "in", page));
            result.push(...messages);
        }
        return result;
    }

    public async searchByUser(user: any, progress?: (message: string, total: number, done: number) => void): Promise<any[]> {

        const result = [] as any[];
        // const chats = (await mtproto.call("messages.getAllChats", {
        //     except_ids: [1304428537, 1371359032],
        //     limit: 100,
        //     offset_order: 0,
        //     offset_chat_id: 0,
        // })).chats.filter((c: any) => c.access_hash);

        const chats = (await mtproto.call("messages.getCommonChats", {
            user_id: {
                _: "inputPeerUser",
                user_id: user.id,
                access_hash: user.access_hash
            },
            limit: 100,
            offset_order: 0,
            offset_chat_id: 0,
        })).chats.filter((c: any) => c.access_hash);

        let done = 0;
        let total = chats.length;
        for (let chat of chats) {
            try {
                let hash = 0;
                hash = (((hash * 0x4f25) & 0x7fffffff) + user.id) & 0x7fffffff;
                hash = (((hash * 0x4f25) & 0x7fffffff) + chat.id) & 0x7fffffff;

                const { messages } = await mtproto.call("messages.search", {
                    peer: {
                        _: "inputPeerChannel",
                        channel_id: chat.id,
                        access_hash: chat.access_hash,
                    },
                    peer_: { _: "inputPeerEmpty" },
                    from_id: {
                        _: "inputPeerUser",
                        user_id: user.id,
                        access_hash: user.access_hash,
                    },
                    filter: { _: "inputMessagesFilterEmpty" },
                    limit: 99,
                    hash: hash
                });
                messages.forEach((message: any) => {
                    message.chat = { id: chat.id, access_hash: chat.access_hash, title: chat.title };
                });
                result.push(...messages.filter((m: any) => m.from_id && m.from_id.user_id === user.id));

                done++;

                console.log(`${messages.length} in ${chat.title}`);
                if (typeof progress === 'function') {
                    progress(`${user.first_name} ${messages.length} in ${chat.title} (${done}/${total})`, total, done);
                }
                await sleep();
            } catch (e) {
                console.warn(e, chat);
            }

        }
        await this.save(result);
        console.log(`loaded ${result.length} messages`);
        if (typeof progress === 'function') {
            progress(`${user.first_name} ${result.length} messages`, total, done);
        }
        return result;
    }

    public async searchByText(chats: any[], text: string, count: number, progress?: (message: string, total: number, done: number) => void): Promise<any[]> {

        const result = [] as any[];

        let done = 0;
        let total = chats.length;
        for (let chat of chats) {
            try {
                let hash = 0;
                hash = (((hash * 0x4f25) & 0x7fffffff) + text.length) & 0x7fffffff;
                hash = (((hash * 0x4f25) & 0x7fffffff) + chat.id) & 0x7fffffff;

                const { messages } = await mtproto.call("messages.search", {
                    peer: {
                        _: "inputPeerChannel",
                        channel_id: chat.id,
                        access_hash: chat.access_hash,
                    },
                    from_id: { _: "inputPeerEmpty" },
                    filter: { _: "inputMessagesFilterEmpty" },
                    limit: count,
                    hash: hash,
                    q: text
                });
                messages.forEach((message: any) => {
                    message.chat = { id: chat.id, access_hash: chat.access_hash, title: chat.title };
                });
                result.push(...messages.filter((m: any) => m.from_id));

                done++;

                console.log(`${messages.length} in ${chat.title}`);
                if (typeof progress === 'function') {
                    progress(`${messages.length} in ${chat.title} (${done}/${total})`, total, done);
                }
                await sleep();
            } catch (e) {
                console.warn(e, chat);
            }

        }
        await this.save(result);
        console.log(`loaded ${result.length} messages`);
        if (typeof progress === 'function') {
            progress(`${result.length} messages`, total, done);
        }
        return result;
    }

    public async loadPrivateMessage(user: any): Promise<any[]> {

        const history = await getDocs(PrivateMessagesStore.where("peer_id.user_id", "==", user.id));
        const last_id = history.length ? history[history.length - 1].id : 0;
        console.log("lastid", last_id, history[history.length - 1]);
        const { messages, count } = await mtproto.call("messages.getHistory", {
            peer: {
                _: "inputPeerUser",
                user_id: user.id,
                access_hash: user.access_hash
            },
            min_id: last_id,
            offset: 0,
            limit: 99,
        });
        messages.forEach((message: any) => {
            message._id = message.peer_id.user_id + '_' + message.id;
            if (message.fwd_from) {
                message.from_id = message.fwd_from.from_id || message.from_id;
                message.date = message.fwd_from.date;
            }

            if (message.media && message.media && message.media.photo) {
                message.media = { photo: message.media.photo };
            }
            else {
                delete message.media;
            }

            if (message.media && message.media.photo && message.media.photo.file_reference) {
                message.media.photo.file_reference = Array.from(message.media.photo.file_reference);
            }
            if (message.media && message.media.photo && message.media.photo.sizes[0] && message.media.photo.sizes[0].bytes) {
                message.media.photo.sizes[0].bytes = Array.from(message.media.photo.sizes[0].bytes);
            }

            delete message.fwd_from;
            delete message.edit_hide;
            delete message.flags;
            delete message.from_scheduled;
            delete message.legacy;
            delete message.media_unread;
            delete message.mentioned;
            delete message.pinned;
            delete message.post;
            delete message.replies;
            delete message.silent;
            delete message._;
        });

        await saveBatch(PrivateMessagesStore, messages, (m: any) => m._id)

        messages.sort((a: any, b: any) => a.id - b.id);

        return history.concat(messages);
    }

    private async save(messages: any[]): Promise<void> {

        messages.forEach((message: any) => {
            message._id = message.id + '_' + message.chat.id;
            if (message.fwd_from) {
                message.from_id = message.fwd_from.from_id || message.from_id;
                message.date = message.fwd_from.date;
            }

            if (message.media && message.media && message.media.photo) {
                message.media = { photo: message.media.photo };
            }
            else {
                delete message.media;
            }

            if (message.media && message.media.photo && message.media.photo.file_reference) {
                message.media.photo.file_reference = Array.from(message.media.photo.file_reference);
            }
            if (message.media && message.media.photo && message.media.photo.sizes[0] && message.media.photo.sizes[0].bytes) {
                message.media.photo.sizes[0].bytes = Array.from(message.media.photo.sizes[0].bytes);
            }

            delete message.edit_hide;
            delete message.flags;
            delete message.from_scheduled;
            delete message.legacy;
            delete message.media_unread;
            delete message.mentioned;
            delete message.out;
            delete message.pinned;
            delete message.post;
            delete message.replies;
            delete message.silent;
            delete message._;
        });

        await saveBatch(MessagesStore, messages.filter((m: any) => m.from_id && m.from_id.user_id && m.peer_id.channel_id), (m: any) => m._id);

    }

    public async onMTProtoMessage(updateInfo: any) {
        /*
        date: 1633616511
flags: 0
id: 26497
media_unread: false
mentioned: false
message: "ыа"
out: false
pts: 74992
pts_count: 1
silent: false
user_id: 505614826
_: "updateShortMessage"
 */
        // const updates = updateInfo.updates.filter((u: any) => u._ == "updateNewMessage");
        // console.log(updates);

        //if (!updateInfo.chats.length){
        console.log(updateInfo);
        //}
    }



}