import { sleep } from '@/utils/utils';
import Vue from 'vue'
import { IMTProto } from './types';
const MTProto = require('@mtproto/core/envs/browser');

const api_id = '932744'
const api_hash = '09935bf17fa25b476f1421db7fbb6b8c'

// 1. Create an instance
const mtproto = new MTProto({
  api_id,
  api_hash,
});

class MTProtoExt implements IMTProto {
  async call(method: any, params?: any, options?: any): Promise<any> {
    try {
      const result = await mtproto.call(method, params, options);
      return result;
    }
    catch (e) {
      if (e.error_code === 420 && e.error_message) {
        const waitStr = e.error_message.substring(11);
        const wait = parseInt(waitStr) * 1000;
        console.warn(`wait for ${waitStr} sec (${e.error_message})... now ${new Date().toLocaleTimeString()}`);
        await sleep(wait);
        console.log('continue...');
        return this.call(method, params, options);
      }
      else {
        throw e;

      }
    }
  }

  get updates() {
    return mtproto.updates;
  }


}

const mtprotoExt = new MTProtoExt;

Vue.mixin({
  beforeCreate() {
    this.$mtproto = mtprotoExt;
  }
})

export default mtprotoExt;

//error_code: 420
//error_message: "FLOOD_WAIT_3"