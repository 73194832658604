const Datastore = require('nedb');
import { paginate, sleep } from "@/utils/utils";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyAR-xeuNFETGzclNDp7YNtuhn586Asxcv4",
    authDomain: "electric-attic-145016.firebaseapp.com",
    databaseURL: "https://electric-attic-145016.firebaseio.com",
    projectId: "electric-attic-145016",
    storageBucket: "electric-attic-145016.appspot.com",
    messagingSenderId: "910417489815",
    appId: "1:910417489815:web:0457db1becf91968b29ed2"
};

// const firebaseConfig = {
//     apiKey: "AIzaSyA_MPFSBZl7AoSe9e3EVhaLSOEz6nTU11M",
//     authDomain: "tgv2-60db4.firebaseapp.com",
//     projectId: "tgv2-60db4",
//     storageBucket: "tgv2-60db4.appspot.com",
//     messagingSenderId: "88547276815",
//     appId: "1:88547276815:web:2e486fb1da380c2c7b0b7a"
//   };

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
const firestore = fire.firestore();

const auth = firebase.auth();
auth.onAuthStateChanged(user => {
    if (!user) {
        auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(console.log);
    }
})


class PromiseDatastore {
    public Store: any;
    constructor(filename: string) {
        this.Store = new Datastore({ filename: filename, autoload: true });
    }

    private promising(method: string, ...arg: any[]) {
        const store = this.Store;
        return new Promise(function (success, fail) {
            const cb = function (err: any, result: any) {
                if (err) return fail(err);
                success(result);
            };
            store[method].apply(store, [...arg, cb]);
        });
    }

    insert(...arg: any[]) {
        return this.promising('insert', ...arg);
    }

    update(...arg: any[]) {
        return this.promising('update', ...arg);
    }

    find(...arg: any[]) {
        return this.promising('find', ...arg);
    }
    remove(...arg: any[]) {
        return this.promising('remove', ...arg);
    }
}

export async function saveBatch(collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>, data: any[], kyeF: any) {
    const pages = paginate(data, 500);
    for (let page of pages) {
        const batch = fire.firestore().batch();
        for (let item of page) {
            const sfRef = collection.doc(kyeF(item).toString());
            batch.set(sfRef, item);
        }
        await batch.commit();
    }
}

export async function getDocs(query: firebase.firestore.Query<firebase.firestore.DocumentData>): Promise<any[]> {
    const result = await query.get();
    if (result.empty) return [] as any[];
    return result.docs.map(d => d.data()) as any[];
}

const UsersStore = firestore.collection('users');// new PromiseDatastore('users');
const UsersFavouriteStore = firestore.collection('usersFavourite'); //new PromiseDatastore('usersFavourite');
const UsersBlockedStore = firestore.collection('usersBlocked'); //new PromiseDatastore('usersBlocked');
const MessagesStore = firestore.collection('messages');// new PromiseDatastore('messages');
const PrivateMessagesStore = firestore.collection('private_messages');// new PromiseDatastore('messages');
const PhotosStore = firebase.storage().ref();

// (async function s() {
//     const request = indexedDB.open('photoCache', 1);

//     request.onsuccess = () => {
//         const db = request.result as IDBDatabase;
//         db.onerror = function () {
//             console.log("Error creating/accessing IndexedDB database");
//         };

//         var objectStore = db.transaction('photoCache', "readonly")!.objectStore('photoCache');
//         let i = 0;
//         const array = [] as IDBCursorWithValue[];
//         objectStore.openCursor().onsuccess = async function (event) {
//             const target = event.target as IDBRequest;
//             const result = target.result as IDBCursorWithValue;
//             const key = result.key as string;
//             const value = result.value;
//             if (key && value) {
//                 array.push(result);
//             }
//             result.continue();

//             for(let item of array){
//                 const fileRef = PhotosStore.child(item.key.toString());
//                 var newMetadata = {
//                     cacheControl: 'public,max-age=31536000 ',
//                     contentType: 'image/jpeg'
//                 }
//                 fileRef.put(item.value, newMetadata).then(function name(file) {
//                     console.log(i++, file);
//                 })
//                 await sleep(500);
//             }
//         };
//     }
// })();

// (async function name() {
//     //debugger;

//     const col = fire.firestore().collection("messages");
//     const r = await col.where("_id", "==", "100566_1262579742").where("id", "==", 100566).get();
//     console.log(r.empty);
//     const doc = r.docs[0].data();
//     col.doc(doc._id).set(doc)
//     // const users = await MessagesStore.find({}) as any[];
//     // console.log(`users start ${users.length}`);

//     // const pages = paginate(users, 500);
//     // let i = 1;
//     // for (let page of pages) {
//     //     const batch = fire.firestore().batch();
//     //     console.log(i++, page.length);
//     //     for (let user of page) {
//     //         const sfRef = col.doc(user._id);
//     //         batch.set(sfRef, user);
//     //     }
//     //     await batch.commit();
//     // }

//     // console.log(`users done ${users.length}`);
// })()

export { UsersStore, UsersBlockedStore, MessagesStore, UsersFavouriteStore, fire, PhotosStore, PrivateMessagesStore };