import mtproto from '@/plugins/mtproto';
import { PhotosStore } from './StoreService';

export declare class Photo {
    _: 'photo';
    /** True, if stickers were added to the photo */
    hasStickers: boolean;
    /** Photo minithumbnail; may be null */
    /** Available variants of the photo, in different sizes */
    sizes: PhotoSize[];
    access_hash: string;
    date: number;
    file_reference: Uint8Array;
    id: string;
    dc_id: number;
    hash: number;
}

export declare class PhotoSize {
    _: 'photoSize';
    /** Thumbnail type (see https://core.telegram.org/constructor/photoSize) */
    type: string;
    /** Information about the photo file */
    photo: PhotoSizeFile;
    /** Photo width */
    w: number;
    /** Photo height */
    h: number;
    bytes?: []
    size: number;
}

export declare class PhotoSizeFile {
    local_id: number;
    volume_id: string;
}

export declare class UserPhotoFile {
    url: string
    size: PhotoSize
}

class PhotoService {

    public async getPhoto(photo: Photo, size?: string): Promise<UserPhotoFile | null> {

        return new Promise(async (success, fail) => {

            const maxSize = photo.sizes.reduce((prev, current) => {
                return (current.size > prev.size || !prev.size) ? current : prev;
            }, photo.sizes[0]);

            const minSize = photo.sizes.reduce((prev, current) => {
                return (current.size < prev.size || !prev.size) ? current : prev;
            }, photo.sizes[0]);

            const exactSize = photo.sizes.find(p => p.type == size);

            const sizeToLoad = size && exactSize ? exactSize :
                size && !exactSize ? minSize :
                    maxSize;


            const key = photo.id + '-' + sizeToLoad.type;
            try {
                const url = await PhotosStore.child(key).getDownloadURL();
                success({ url, size: sizeToLoad });

            } catch (e) {
                const array = await this.loadPhoto(photo, sizeToLoad);
                const fileRef = PhotosStore.child(key);
                var newMetadata = {
                    cacheControl: 'public,max-age=31536000 ',
                    contentType: 'image/jpeg'
                }
                fileRef.put(array, newMetadata).then(async (file) => {
                    const url = await file.ref.getDownloadURL();
                    success({ url, size: sizeToLoad });
                })
            }

        });
    }

    private async loadPhoto(photo: Photo, sizeToLoad: PhotoSize): Promise<Uint8Array> {

        try {

            //console.log('load ' + photo.id + '-' + sizeToLoad.type, photo, sizeToLoad, photo.file_reference.length);
            const result = new Uint8Array(sizeToLoad.size);
            let offset = 0;
            while (offset < sizeToLoad.size) {
                const file = await mtproto.call("upload.getFile", {
                    cdn_supported: false,
                    precise: false,
                    offset: offset,
                    limit: 4096 * 4,
                    location: {
                        _: "inputPhotoFileLocation",
                        id: photo.id,
                        access_hash: photo.access_hash,
                        file_reference: this.normalizeByteArray(photo.file_reference),
                        thumb_size: sizeToLoad.type,
                    },
                }, { dcId: photo.dc_id });
                //console.log(photo.id + '-' + sizeToLoad.type, file.bytes.length, " of ", sizeToLoad.size);
                result.set(file.bytes, offset);
                offset += file.bytes.length;
            }
            return result;
        } catch (e) {
            console.error(e, photo);
            throw e;

        }

    }

    public getThumb(photo: Photo): string {
        var header = "\xff\xd8\xff\xe0\x00\x10\x4a\x46\x49" +
            "\x46\x00\x01\x01\x00\x00\x01\x00\x01\x00\x00\xff\xdb\x00\x43\x00\x28\x1c" +
            "\x1e\x23\x1e\x19\x28\x23\x21\x23\x2d\x2b\x28\x30\x3c\x64\x41\x3c\x37\x37" +
            "\x3c\x7b\x58\x5d\x49\x64\x91\x80\x99\x96\x8f\x80\x8c\x8a\xa0\xb4\xe6\xc3" +
            "\xa0\xaa\xda\xad\x8a\x8c\xc8\xff\xcb\xda\xee\xf5\xff\xff\xff\x9b\xc1\xff" +
            "\xff\xff\xfa\xff\xe6\xfd\xff\xf8\xff\xdb\x00\x43\x01\x2b\x2d\x2d\x3c\x35" +
            "\x3c\x76\x41\x41\x76\xf8\xa5\x8c\xa5\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8" +
            "\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8" +
            "\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8\xf8" +
            "\xf8\xf8\xf8\xf8\xf8\xff\xc0\x00\x11\x08\x00\x00\x00\x00\x03\x01\x22\x00" +
            "\x02\x11\x01\x03\x11\x01\xff\xc4\x00\x1f\x00\x00\x01\x05\x01\x01\x01\x01" +
            "\x01\x01\x00\x00\x00\x00\x00\x00\x00\x00\x01\x02\x03\x04\x05\x06\x07\x08" +
            "\x09\x0a\x0b\xff\xc4\x00\xb5\x10\x00\x02\x01\x03\x03\x02\x04\x03\x05\x05" +
            "\x04\x04\x00\x00\x01\x7d\x01\x02\x03\x00\x04\x11\x05\x12\x21\x31\x41\x06" +
            "\x13\x51\x61\x07\x22\x71\x14\x32\x81\x91\xa1\x08\x23\x42\xb1\xc1\x15\x52" +
            "\xd1\xf0\x24\x33\x62\x72\x82\x09\x0a\x16\x17\x18\x19\x1a\x25\x26\x27\x28" +
            "\x29\x2a\x34\x35\x36\x37\x38\x39\x3a\x43\x44\x45\x46\x47\x48\x49\x4a\x53" +
            "\x54\x55\x56\x57\x58\x59\x5a\x63\x64\x65\x66\x67\x68\x69\x6a\x73\x74\x75" +
            "\x76\x77\x78\x79\x7a\x83\x84\x85\x86\x87\x88\x89\x8a\x92\x93\x94\x95\x96" +
            "\x97\x98\x99\x9a\xa2\xa3\xa4\xa5\xa6\xa7\xa8\xa9\xaa\xb2\xb3\xb4\xb5\xb6" +
            "\xb7\xb8\xb9\xba\xc2\xc3\xc4\xc5\xc6\xc7\xc8\xc9\xca\xd2\xd3\xd4\xd5\xd6" +
            "\xd7\xd8\xd9\xda\xe1\xe2\xe3\xe4\xe5\xe6\xe7\xe8\xe9\xea\xf1\xf2\xf3\xf4" +
            "\xf5\xf6\xf7\xf8\xf9\xfa\xff\xc4\x00\x1f\x01\x00\x03\x01\x01\x01\x01\x01" +
            "\x01\x01\x01\x01\x00\x00\x00\x00\x00\x00\x01\x02\x03\x04\x05\x06\x07\x08" +
            "\x09\x0a\x0b\xff\xc4\x00\xb5\x11\x00\x02\x01\x02\x04\x04\x03\x04\x07\x05" +
            "\x04\x04\x00\x01\x02\x77\x00\x01\x02\x03\x11\x04\x05\x21\x31\x06\x12\x41" +
            "\x51\x07\x61\x71\x13\x22\x32\x81\x08\x14\x42\x91\xa1\xb1\xc1\x09\x23\x33" +
            "\x52\xf0\x15\x62\x72\xd1\x0a\x16\x24\x34\xe1\x25\xf1\x17\x18\x19\x1a\x26" +
            "\x27\x28\x29\x2a\x35\x36\x37\x38\x39\x3a\x43\x44\x45\x46\x47\x48\x49\x4a" +
            "\x53\x54\x55\x56\x57\x58\x59\x5a\x63\x64\x65\x66\x67\x68\x69\x6a\x73\x74" +
            "\x75\x76\x77\x78\x79\x7a\x82\x83\x84\x85\x86\x87\x88\x89\x8a\x92\x93\x94" +
            "\x95\x96\x97\x98\x99\x9a\xa2\xa3\xa4\xa5\xa6\xa7\xa8\xa9\xaa\xb2\xb3\xb4" +
            "\xb5\xb6\xb7\xb8\xb9\xba\xc2\xc3\xc4\xc5\xc6\xc7\xc8\xc9\xca\xd2\xd3\xd4" +
            "\xd5\xd6\xd7\xd8\xd9\xda\xe2\xe3\xe4\xe5\xe6\xe7\xe8\xe9\xea\xf2\xf3\xf4" +
            "\xf5\xf6\xf7\xf8\xf9\xfa\xff\xda\x00\x0c\x03\x01\x00\x02\x11\x03\x11\x00" +
            "\x3f\x00";
        const footer = "\xff\xd9";


        const pic = photo.sizes[0];
        if (!pic || pic.type !== "i") return "";

        const headerArray = this.string2Bin(header);
        const footerArray = this.string2Bin(footer);

        const picArray = this.normalizeByteArray(pic.bytes);
        const picSize = headerArray.length + picArray.length - 3 + footerArray.length;
        const result = new Uint8Array(picSize);
        result.set(headerArray);
        result.set([picArray[1]], 164);
        result.set([picArray[2]], 166);
        result.set(picArray.slice(3), headerArray.length);
        result.set(footerArray, picSize - 2);


        return createObjectURL(result);
    }

    public calcHash(photo: Photo): number | undefined {
        const pic = photo.sizes[0];

        if (!pic || !pic.bytes) return undefined;

        if (photo.hash) return photo.hash;

        const picArray = this.normalizeByteArray(pic.bytes);

        let hash = 0, i, chr;
        if (picArray.length === 0) return undefined;
        for (i = 0; i < picArray.length; i++) {
            chr = picArray[i];
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        
        photo.hash = hash;

        return hash;

    }

    private string2Bin(str: string): number[] {
        var result = [];
        for (var i = 0; i < str.length; i++) {
            result.push(str.charCodeAt(i));
        }
        return result;
    }

    private normalizeByteArray(bytes: any): number[] {
        return bytes instanceof Array ? bytes as number[] : Array.from(Object.values(bytes as Object));
    }

}
export function createImageUrlString(array: Uint8Array): string {
    return "data:image/jpeg;base64," +
        btoa(
            array.reduce(
                (data, byte) => data + String.fromCharCode(byte),
                "")
        );
}
export function createObjectURL(array: Uint8Array) {
    var superBuffer = new Blob([array], { type: 'image/jpeg' });
    return window.URL.createObjectURL(superBuffer);
}
export default new PhotoService();