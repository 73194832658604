













import Vue from "vue";
import Navigator from "./components/Navigator.vue";
import store from "@/store/index";

export default Vue.extend({
  name: "App",

  components: {
    Navigator,
  },

  data: () => ({
    //
  }),
  created() {
    // this.$mtproto.call("help.getNearestDc").then((result) => {
    //   console.log(`country:`, result.country);
    // });

    // this.$mtproto
    //   .call("users.getUsers", {
    //     id: [{ _: "inputUserSelf" }],
    //   })
    //   .then((user) => {
    //     this.$store.commit("setCurrentUser", user[0]);
    //   });

    // this.$mtproto
    //   .call("messages.getChats", { id: [1124189825], limit: 100 })
    //   .then((result) => {
    //     console.log(`messages.getChats:`, result);
    //   });

    // this.$mtproto.call("upload.getFile", {
    //   cdn_supported: false,
    //   precise: false,
    //   offset: 0,
    //   limit: 4096,
    //   location: {
    //     _: "inputPeerPhotoFileLocation",
    //     big: true,
    //     volume_id: "200123900081",
    //     local_id: 83792,
    //     peer: {
    //       '_': 'inputPeerUser',
    //       user_id: 1231195778,
    //       access_hash: "2500466316018771206"
    //     },
    //   },
    // }).then(console.log);
  },
});
