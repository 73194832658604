






































import Vue from "vue";
import Component from "vue-class-component";
import MemberProfile from "@/components/MemberProfile.vue";
import { MessagesService } from "@/services/MessagesService";
import MemberPhoto from "@/components/MemberPhoto.vue";

@Component<Chats>({
  components: {
    MemberProfile,
    MemberPhoto,
  },
  watch: {
    selected(value) {
      this.loadMessages(value);
    },
  },
})
export default class Chats extends Vue {
  private messagesService = new MessagesService();

  users = [];
  selected: number | null = null;

  async load() {
    this.selected = null;
    const { dialogs, users, messages } = await this.$mtproto.call(
      "messages.getDialogs",
      {
        offset_peer: { _: "inputPeerEmpty" },
        limit: 100,
        offset_id: -1,
      }
    );
    users.map((user: any) => {
      user.messages = [];
      user.photos = [];
      user.private_messages = [];
    });
    this.users = users;

    //console.log(dialogs);
  }

  changePhotoSize(photo: any) {
    if (photo.photoSize == "m") {
      Vue.set(photo, "photoSize", "i");
    } else {
      Vue.set(photo, "photoSize", "m");
    }
  }

  loadMessages(userid: number) {
    const u = this.users[userid];
    if (u) {
      this.messagesService.loadPrivateMessage(u).then((result) => {
        (u as any).private_messages = result;
      });
    }
  }

  get user() {
    return this.selected != null ?  this.users[this.selected] : null;
  }

  created() {
    this.load();
  }
}
