<template>
  <v-app-bar color="grey lighten-3" dense flat app>
    <v-toolbar-title>MyTelegram</v-toolbar-title>

    <v-btn
      text
      small
      v-for="item in items"
      :key="item.title"
      router
      :to="item.route"
      >{{ item.title }}</v-btn
    >

    <v-spacer></v-spacer>
   
    <v-progress-linear
     v-model="$store.state.progress"  height="25" v-if="$store.state.progress"
    >
   
    </v-progress-linear>
   {{ $store.state.message }}
    <!-- <v-btn text @click="loadMore" v-if="$store.state.itemsCount">
      Загружено {{ $store.state.itemsCount }}
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn icon @click="reload">
      <v-icon>mdi-reload</v-icon>
    </v-btn> 

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item router to="/map">Карта</v-list-item>
        <v-list-item router to="/settings">Настройки</v-list-item>
        <v-list-item v-for="n in 5" :key="n" @click="() => {}">
          <v-list-item-title>Option {{ n }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>-->
  </v-app-bar>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Navigator",
  data: () => ({
    items: [
      { title: "Все чаты", icon: "home", route: "/" },
      { title: "Избранные", icon: "home", route: "/MyFavourite" },
      { title: "Чаты", icon: "home", route: "/chats" },
      { title: "Поиск", icon: "home", route: "/search" },
      { title: "Войти", icon: "home", route: "/login" },
    ],
  }),
  methods: {
    reload() {
      //this.$root.$emit("reload");
      var vm = this.$router.currentRoute.matched[0].instances.default;
      if (vm["reload"]) {
        vm.reload();
      }
    },
    loadMore() {
      //this.$root.$emit("loadMore");
      var vm = this.$router.currentRoute.matched[0].instances.default;
      if (vm["loadMore"]) {
        vm.loadMore();
      }
    },
  },
});
</script>