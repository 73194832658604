




import Vue from "vue";
import Component from "vue-class-component";

// Define the props by using Vue's canonical way.
const UserPhotoProps = Vue.extend({
  props: {
    user: Object,
  },
});

@Component({})
export default class extends UserPhotoProps {
  photo: any = null;
  async created() {
    this.photo = this.user.photo;
    if (!this.photo) return;

    console.log(this.photo);
    var param = {
      cdn_supported: false,
      precise: false,
      offset: 0,
      limit: 4096,
      location: {
        _: "inputPeerPhotoFileLocation",
        big: false,
        volume_id: this.photo.photo_small.volume_id,
        local_id: this.photo.photo_small.local_id,
        peer: {
          _: "inputPeerUser",
          user_id: this.user.id,
          access_hash: this.user.access_hash,
        },
      },
    };
    console.log(param);
    const file = await this.$mtproto.call("upload.getFile", param);

    console.log(file);
  }
}
