



























import Vue from "vue";
import Component from "vue-class-component";

import {
  distinct,
  distinctByProp,
  groupBy,
  groupByF,
  paginate,
  sleep,
} from "@/utils/utils";
import MemberProfile from "@/components/MemberProfile.vue";
import { UserService } from "@/services/UserService";
import { MessagesService } from "@/services/MessagesService";

@Component({
  components: {
    MemberProfile,
  },
})
export default class extends Vue {
  private messagesService = new MessagesService();
  private userService = new UserService();

  messages: any[] = [];
  users: any[] = [];
  searchText = "";

  async loadMessages() {
    this.messages.splice(0);
    this.users.splice(0);

    let users = await this.userService.getFavourite();

    const myMessages = await this.messagesService.loadMyMessages();

    const usersFromMyMessages =
      await this.userService.loadUsersFromForwardedMessages(myMessages);

    users.splice(users.length, 0, ...usersFromMyMessages);

    const usersIds = users.map((u) => u.id);
    const messages = await this.messagesService.loadFromCache(usersIds);

    messages.push(...myMessages);

    this.messages.splice(0, 0, ...messages);

    const group = groupByF(this.messages, (m: any) => m.from_id.user_id);

    for (let user of users) {
      user.messages = group[user.id] || [];
      try {
        user.photos = await this.userService.getUserPhotos(user);
      } catch {}
    }
    users.sort(
      (a, b) =>
        b.messages &&
        b.messages[0] &&
        b.messages[0].date - a.messages &&
        a.messages[0] &&
        a.messages[0].date
    );
    users = distinctByProp(users, "id");
    this.users.splice(this.users.length, 0, ...users);
  }

  async blockUser(user: any) {
    this.users.splice(this.users.indexOf(user), 1);
  }

  get filteredUsers(): any[] {
    if (!this.searchText) return this.users;

    var st = this.searchText.toLocaleUpperCase();
    return this.users.filter((user: any) => {
      return (
        (user.first_name && user.first_name.toLocaleUpperCase().includes(st)) ||
        (user.last_name && user.last_name.toLocaleUpperCase().includes(st)) ||
        (user.username && user.username.toLocaleUpperCase().includes(st)) ||
        (user.messages &&
          user.messages.some((m: any) => {
            return (
              m.message &&
              (m.message.toLocaleUpperCase().includes(st) ||
                (m.chat &&
                  m.chat.title &&
                  m.chat.title.toLocaleUpperCase().includes(st)))
            );
          }))
      );
    });
  }

  async created() {
    await this.loadMessages();
  }
}
